export default [
  {
    slug: "projects",
    title: "projects",
    metadata: {
      items: [
        {
          title: "Team Dynamics (On going)",
          content:
            "Who doesn't love to see their teams gelling up well and loving what they do? So, get your teams back in the game with great synergy and productivity!",
          link: "https://dynamics.getboarded.tech/",
          image: "teamdynamics.png",
          tag: "Application",
          color: "purple",
          date: "2022-07-28",
        },
        {
          title: "Seakr carer",
          content: "To introduce care seekers to seakr carers with humanity, clarity and speed.",
          link: "https://seakr.co.uk/",
          image: "seakr.png",
          tag: "Application",
          color: "blue",
          date: "2022-07-25",
        },
        {
          title: "Holi",
          content:
            "Holi is the Personal Trainer for your mental health, and he is ready to help you improve your emotional and psychological wellbeing, boosting all fields of your life.",
          link: "https://play.google.com/store/apps/details?id=tech.nevaro.holi",
          image: "Tracker.jpg",
          tag: "Application",
          color: "red",
          date: "2022-6-01",
        },
      ],
      title: "My projects",
      description: "This is my story",
    },
  },
  {
    slug: "skills",
    title: "skills",
    metadata: {
      items: [
        {
          title: "C",
          img: "C.png",
        },
        {
          title: "C++",
          img: "cplusplus.png",
        },
        {
          title: "C#",
          img: "Csharp.png",
        },
        {
          title: "Java",
          img: "java.png",
        },
        {
          title: "Unity",
          img: "unity.png",
        },
        {
          title: "HTML",
          img: "html.png",
        },
        {
          title: "CSS",
          img: "css.png",
        },
        {
          title: "PHP",
          img: "php.png",
        },
        {
          title: "Laravel",
          img: "laravel.png",
        },
        {
          title: "VueJS",
          img: "vuejs.png",
        },
        {
          title: "Android",
          img: "android.png",
        },
        {
          title: "Linux",
          img: "linux.png",
        },
        {
          title: "Git/Github",
          img: "Octocat.png",
        },
        {
          title: "Go",
          img: "go.png",
        },
        {
          title: "Flutter",
          img: "flutter.png",
        },
      ],
      title: "Skills",
      description: "Here is my armament",
    },
  },
  {
    slug: "links",
    title: "links",
    metadata: {
      facebook: "https://www.facebook.com/people/Muhammad-Ahmad/100006315696643/",
      instagram: "https://www.instagram.com/_.ahmadt/",
      linkedin: "https://www.linkedin.com/in/fateahmad/",
      github: "https://gitlab.com/fateahmad",
    },
  },
  {
    slug: "user-data",
    title: "user-data",
    metadata: {
      name: "Muhammad Ahmad",
      status: "Software Engineer",
      email: "ahmad.arshad19@yahoo.com",
      phone: "+92 300 4467414",
      city: "Lahore, Pakistan",
      lang: "Urdu, English, Hindi",
      photo: "photo.jpg",
    },
  },
  {
    slug: "description",
    title: "description",
    metadata: {
      pres_second:
        "I have experience rolling out projects from the initial communication with client and design phases, to understanding the main scope of work, recommending architecture and technologies, setting up development environments, developing new codebase from scratch or working with legacy codebase, and until the release of production app and subsequent support and maintenance.",
      pres_first:
        "Innovative Android Developer with 1+ year of experience designing, developing, testing, and maintaining Android applications using flutter in the sports entertainment industry.",
      pres_title: "Who am I ?",
      description: "Hope to know you after",
      title: "About Me",
    },
  },
  {
    slug: "experiences",
    title: "experiences",
    metadata: {
      description: "Professional and academic",
      title: "Experience",
      professional: [
        {
          year: "04.2021 - 02.2022",
          title: "Flutter Developer",
          content: "Junior Android/Flutter developer",
        },
        {
          year: "05.2022",
          title: "Flutter Developer",
          content: "Worked on a variety of projects in social and entertainment market",
        },
      ],
      academic: [
        {
          year: "From September 2019",
          title: "Software Engineering",
          content: "Specialized in android and collaborative systems",
        },
        {
          year: "2018 - 2019",
          title: "FSc",
          content: "GC College Lahore",
        },
        {
          year: "2015 - 2017",
          title: "Matriculation",
          content: "Qazi pilot high school Lahore",
        },
      ],
    },
  },
];
