export default [
  {
    slug: "user-data",
    title: "user-data",
    metadata: {
      name: "Muhammad Ahmad",
      status: "Software Engineer",
      email: "ahmad.arshad19@yahoo.com",
      phone: "+92 300 4467414",
      city: "Lahore, Pakistan",
      lang: "Urdu, English, Hindi",
      photo: "photo.jpg",
    },
  },
];
